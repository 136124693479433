import axios, { isCancel } from 'axios';

const instance = axios.create({
  // baseURL: 'http://84.201.174.163:6045/api/',
  // baseURL: 'http://localhost:6045/api/',
  // baseURL: 'http://10.128.0.17:6045/api/',
  baseURL: 'https://preapproval-test.mainslab.ai/api/'
});

export { isCancel };
export default instance;
